import { createApp, version } from 'vue/dist/vue.esm-bundler.js'

// License
import license from './license'

// Sentry
import createSentry from 'app/sentry/sentry'

// Helpers
import exceptionHelper from 'app/helpers/exception'

export default function createModule ({ App, components, directives, router, dependencies }) {
  if (typeof App === 'undefined' || !App) throw new exceptionHelper.AppException('App is an required parameter.')

  // Versioning
  window.VUE_APPLICATION = {
    version: process.env.APP_VERSION,
    vue: `Vue ${version}`,
    name: App.name
  }

  const deps = [
    ...router ? [router] : [],
    ...dependencies
  ]

  // Create app
  const app = createApp(App)

  // Create Sentry
  createSentry(app, router)

  // Add components
  if (typeof components === 'object' && Object.entries(components).length) {
    Object.entries(components).forEach(([key, component]) => app.component(key, component))
  }

  // Add directives
  if (typeof directives === 'object' && Object.entries(directives).length) {
    Object.entries(directives).forEach(([key, directive]) => app.directive(key, directive))
  }

  // Add dependencies
  if (Array.isArray(deps) && deps.length) {
    deps.forEach(dependency => app.use(dependency))
  }

  // Bind configurations
  app.config.compilerOptions.whitespace = 'preserve'

  // Mount
  app.mount('#app')

  // License
  license()
}
