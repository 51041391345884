import objectHelper from 'app/helpers/object'
import httpHelper from 'app/helpers/http'

export default {
  SET_LOADING (state, data) {
    state.loading = Object.assign({}, data)
  },
  SET_TRANSITION (state, type) {
    state.transition = type
  },
  SET_ERROR (state, response) {
    if (typeof response === 'undefined') {
      state.error = null
    } else {
      const errors = {
        default: {
          type: 'default'
        },
        'http-error': {
          type: 'http-error'
        }
      }

      const type = (httpHelper.isHttpError(response) ? 'http-error' : 'default')
      state.error = errors[type]
    }
  },
  SET_FORM_FEEDBACK (state, response) {
    state.formFeedback = response
  },
  SET_LANDINGPAGE (state, response) {
    state.landingpage = response || {}
  },
  SET_CONTACT_OPTIONS (state, options) {
    state.contact = options || []
  },
  SET_ACTIVE_QUESTION (state) {
    const activeIndex = state.questions.length - 1
    state.question = state.questions[activeIndex].question
  },
  SET_ACTIVE_ANSWER (state) {
    const id = state.question.id || 0
    const answer = objectHelper.findWhere(state.answers, { questionId: id })

    if (typeof answer !== 'undefined') {
      state.answer = answer
    } else {
      state.answers.push({
        questionId: state.question.id,
        error: false,
        value: ''
      })

      state.answer = state.answers[state.answers.length - 1]
    }
  },
  SET_FINISHED (state, value) {
    state.finished = value
  },
  SET_PROGRESS (state, data) {
    // whenever data is undefined, user is navigating through questions,
    //  which can be next or previous question...
    if (typeof data === 'undefined') {
      const activeIndex = state.questions.length - 1
      data = state.questions[activeIndex].progress
    }

    state.progress = Object.assign({}, state.progress, data)
  },
  SET_TOOL_RESULT (state, data) {
    state.info.tool = Object.assign({}, state.info.tool, data)
  },
  SET_INFO (state, data) {
    // whenever data is undefined, user is navigating through questions,
    //  which can be next or previous question...
    if (typeof data === 'undefined') {
      data = state.question.info || {}
    }

    // tool can be null,
    //  so delete it from the object...
    if (!data.tool) {
      delete data.tool
    }

    state.info = Object.assign({}, {
      id: 0,
      title: '',
      body: '',
      tool: {}
    }, data)
  },
  SET_REGULATION (state, regulation) {
    state.regulation = regulation
  },
  STORE_SOLUTION (state, solution) {
    state.solution = solution
  },
  REMOVE_QUESTION (state) {
    state.questions.pop()
    state.answers.pop()
  },
  RESET_TRANSITION (state) {
    state.transition = 'question-next'
  },
  RESET_ACTIVE_QUESTION (state) {
    state.question = {}
  },
  RESET_ACTIVE_ANSWER (state) {
    state.answer = {}
  },
  RESET_QUESTIONS (state) {
    state.questions = []
  },
  RESET_FINISHED (state) {
    state.finished = false
  },
  RESET_ANSWERS (state) {
    state.answers = []
  },
  RESET_PROGRESS (state) {
    state.progress.value = 0
  },
  STORE_QUESTION (state, response) {
    state.questions.push(response)
  },
  STORE_RESULT (state, data) {
    state.result = data
  }
}
