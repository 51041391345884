/**
 * tool.js
 *
 * Generic mixin to watch changes in the tool data set; when a visitor uses the tool and submits the value,
 *  the value will be pass on to the question components...
 *
 * @author     Ken van den Broek    <k.vandenbroek@uselab.com>
 * @copyright  2019 Uselab.com
 *
 */

export default {

  /**
     * mounted()
     *
     * The watcher will be attached to the component where mixin has been included...
     *
     * It will watch the value which can be generated by the tool and pass it on
     *  the component using the setValue method...
     *
     * When including this mixin, make sure the setValue method has been created in the question partial,
     *  unfortunately this can't be a generic method for all questions...
     */

  mounted () {
    this.$store.watch((state, getters) => getters.getInfo, this.handler, {
      deep: true
    })
  },

  methods: {

    /**
         * handler()
         *
         * Method will be called from the watcher
         *  which will check setValue method on component...
         */

    handler (data) {
      if (typeof this.setValue !== 'function') return
      this.setValue(data)
    }
  }
}
