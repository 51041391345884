<template>
  <div
    class="question question-input"
    :class="getClass()"
  >
    <div class="question-content">
      <div class="prefix">
        {{ data.prefix }}
      </div>
      <div class="input">
        <input
          v-model.trim="v$.answer.$model"
          maxlength="11"
          type="text"
          autocomplete="off"
          autocapitalize="off"
          placeholder="dd-mm-jjjj"
          spellcheck="false"
          @focus="focus = true"
          @blur="focus = false"
          @input="handleValue"
        >
        <div
          v-if="data.class"
          :class="data.class"
        />
      </div>
      <div class="suffix">
        {{ data.suffix }}
      </div>
      <button-tool />
    </div>
    <div class="tail" />
  </div>
</template>

<script>
import { useVuelidate } from '@vuelidate/core'
import { required } from '@vuelidate/validators'

import ButtonTool from 'app/components/shared/button-tool'
import tool from 'app/mixin/tool'

// default numeric regex, which could be
//  overwritten by api question type...
let validator = '^(\\d{1,2})-(\\d{1,2})-(\\d{4})$'

const customRegexValidator = function (value) {
  const regex = new RegExp(validator)
  return regex.test(value)
}

export default {
  components: {
    'button-tool': ButtonTool
  },
  mixins: [tool],
  props: {
    data: {
      type: Object,
      default () {
        return {}
      }
    },
    value: {
      type: String,
      default () {
        return ''
      }
    },
    error: Boolean
  },
  emits: ['input', 'update:error'],
  setup () {
    return { v$: useVuelidate() }
  },
  validations () {
    return {
      answer: {
        required,
        customRegexValidator
      }
    }
  },
  data () {
    return {
      focus: false,
      answer: ''
    }
  },
  mounted () {
    validator = this.data.validator || validator

    this.answer = this.value || ''

    this.v$.answer.$touch()
    this.handleValue()
  },
  methods: {
    getClass () {
      let cssClass

      if (this.v$.answer.$dirty && this.v$.answer.$error && this.answer.length) {
        cssClass = 'question--error'
      } else if (this.focus) {
        cssClass = 'question--focus'
      }

      return cssClass
    },
    handleValue () {
      this.$emit('input', this.answer)
      this.$emit('update:error', this.v$.answer.$error)
    },
    setValue (data) {
      if (typeof data.tool !== 'object') return

      const value = data.tool.value || null

      if (!value) return

      this.v$.answer.$model = value
      this.handleValue()
    }
  }
}
</script>
