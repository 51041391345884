<template>
  <div
    id="page"
    :class="routeName"
  >
    <router-view v-slot="{ Component }">
      <transition
        name="fade"
        mode="out-in"
      >
        <component :is="Component" />
      </transition>
    </router-view>
  </div>
</template>

<script>
export default {
  name: 'Page',
  computed: {
    routeName () {
      return 'page-' + this.$route.name
    }
  }
}
</script>
