/**
 * File helper
 *
 * Helper methods for files
 *
 * @author     K. van den Broek <k.vandenbroek@uselab.com>
 * @copyright  Uselab Amsterdam
 *
 */

export default {

  /**
     * getExtension
     *
     * Get extension of path
     *
     * @param {string} path
     */

  getExtension (path) {
    const pieces = path.split('.')
    return (pieces.length ? pieces[pieces.length - 1] : null)
  }
}
