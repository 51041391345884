<template>
  <div
    :id="id"
    class="lottie-animation"
    :class="{'ie11': isIE}"
  />
</template>

<script>
import lottie from 'lottie-web'
import deviceHelper from 'app/helpers/device'

export default {
  name: 'LottieAnimation',
  props: {
    path: {
      type: String,
      default () {
        return ''
      }
    },
    id: {
      type: String,
      default () {
        return ''
      }
    },
    segments: {
      type: Array,
      default () {
        return []
      }
    },
    frame: {
      type: Number,
      default () {
        return 0
      }
    },
    autoplay: Boolean,
    loop: Boolean
  },
  data () {
    return {
      animation: undefined,
      isIE: false
    }
  },
  watch: {
    path: function () {
      this.create()
    },
    frame () {
      if (this.animation && this.frame) {
        this.animation.playSegments([this.frame, this.frame + 1])
      }
    },
    segments () {
      if (this.animation && this.segments) {
        this.animation.playSegments(this.segments)
      }
    }
  },
  mounted () {
    this.create()
  },
  unmounted () {
    if (this.animation) {
      this.animation.removeEventListener('data_ready')
      this.animation.destroy()
    }
  },
  methods: {
    create () {
      if (!this.path || !this.id) return

      this.isIE = deviceHelper.isIE()

      this.animation = lottie.loadAnimation({
        container: document.getElementById(this.id), // the dom element that will contain the animation
        renderer: this.isIE ? 'canvas' : 'svg',
        loop: this.loop,
        autoplay: this.autoplay,
        path: this.path // the path to the animation json
      })

      this.animation.addEventListener('data_ready', function () {
        if (this.segments && this.segments.length > 0) {
          this.animation.playSegments(this.segments)
        } else if (this.frame) {
          this.animation.playSegments([this.frame - 1, this.frame])
        }
      }.bind(this))
    }
  }
}
</script>
