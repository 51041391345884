/**
 * image.js
 *
 * Generic mixin to handle images...
 *
 * @author     Ken van den Broek    <k.vandenbroek@uselab.com>
 * @copyright  2019 Uselab.com
 *
 */

import imageHelper from 'app/helpers/image'

export default {
  methods: {
    getFile (path) {
      return imageHelper.getFile(path)
    }
  }
}
