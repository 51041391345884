<template>
  <div class="question question-text">
    <div
      v-dompurify-html="getText"
      class="question-content"
    />
    <div class="tail" />
  </div>
</template>

<script>
export default {
  props: {
    data: {
      type: Object,
      default () {
        return {}
      }
    }
  },
  computed: {
    getText () {
      return (this.data.text ? this.data.text : this.$t(`${this.data.lng}`))
    }
  }
}
</script>
