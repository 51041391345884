export default {
  button: {
    info: {
      name: 'btn-info',
      styles: {
        width: '30px',
        height: '30px',
        backgroundSize: '30px 30px',
        marginTop: '0px'
      }
    }
  }
}
