<template>
  <a
    v-show="showTool"
    v-scroll="{el: 'scroll', speed: 400, offset: 40}"
    v-dompurify-html="getName"
    class="link mt-2"
    :class="toggleClass"
  />
</template>

<script>
import { mapState } from 'vuex'

import objectHelper from 'app/helpers/object'
import config from 'app/mixin/config'

export default {
  name: 'ButtonTool',
  mixins: [config],
  computed: {
    ...mapState([
      'info'
    ]),
    getName () {
      const name = objectHelper.get('tool.name', this.info) || null
      const key = this.getConfig(`tool.${name}`)

      return (name ? this.$t(`tool.${key}.button`) : '')
    },
    showTool () {
      return (typeof this.info.tool.name !== 'undefined')
    },
    toggleClass () {
      const name = objectHelper.get('tool.name', this.info) || null
      return 'link-' + (!name ? 'info' : this.getConfig(`tool.${name}`))
    }
  }
}
</script>
