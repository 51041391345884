/**
 *
 * Defaults
 *  All default constants...
 *
 * @author     K. van den Broek <k.vandenbroek@uselab.com>
 * @copyright  Uselab Amsterdam
 *
 */

export default {
  scrollbar: {
    el1Class: 'scrollbar',
    el1ScrollVisibleClass: 'scrollbar-visible',
    el1ScrollInvisibleClass: 'scrollbar-invisible',
    el1ScrollingClass: 'scrollbar-scrolling',
    el1ScrollingPhantomClass: 'scrollbar-scrolling-phantom',
    el1DraggingClass: 'scrollbar-dragging',
    el1DraggingPhantomClass: 'scrollbar-dragging-phantom',
    el2Class: 'scrollbar-content',
    draggerClass: 'scrollbar-dragger',
    draggerStylerClass: 'scrollbar-dragger-styler'
  },
  templates: {
    intro: {
      progress: 0,
      question: {
        id: 0,
        text: {},
        question_type: {
          type: 'text'
        },
        info: {}
      }
    },
    exit: {
      progress: 100,
      question: {
        id: 'exit',
        lng: 'questionnaire.exit',
        question_type: {
          type: 'text'
        },
        info: {}
      }
    }
  },
  button: {
    info: {
      name: 'btn-info',
      styles: {}
    }
  },
  currency: {
    'nl-NL': {
      separator: '',
      decimal: ',',
      symbol: '€',
      formatWithSymbol: true
    }
  },
  tool: {
    IncomeCalculator: 'income-calculator',
    IncomeCalculatorExtended: 'income-calculator'
  },
  timers: {
    timeMsToShowResult: 3000,
    timeMsToStartFinishedProgress: 1000,
    timeMsToScroll: 0
  },
  questionnaire: [
    'question'
  ]
}
