/**
 *
 * Localforage
 *
 * Service which returns localforage instance...
 *
 * @author     K. van den Broek <k.vandenbroek@uselab.com>
 * @copyright  Uselab Amsterdam
 *
 */

import localforage from 'localforage'

export default localforage.createInstance({
  name: process.env.DOMAIN,
  driver: localforage.LOCALSTORAGE
})
