/**
 * Image helper
 *
 * Helper methods for images
 *
 * @author     K. van den Broek <k.vandenbroek@uselab.com>
 * @copyright  Uselab Amsterdam
 *
 */

import fileHelper from './file'
import tenantHelper from './tenant'

export default {

  /**
     * getImageTypes
     *
     * Get accepted image types
     *
     * @return {array}
     */

  getImageTypes () {
    return [
      'jpg',
      'gif',
      'png',
      'svg'
    ]
  },

  /**
     * getFile
     *
     * Get file with correct Tenant public path
     *
     * @param {string} file to retrieve
     * @param {object} [tenant] object (optional)
     */

  getFile (file) {
    const path = tenantHelper.getTenantPublicPath()

    file = (file.substring(0, 1) !== '/') ? '/' + file : file
    file = file.replace(/\/tenant\/[a-z]+\//, '') // todo: remove!

    return `/domain/${path}/${file}`
  },

  /**
     * isImage
     *
     * Check to make sure image is valid type
     *
     * @param {string} path to validate
     */

  isImage (path) {
    if (!path) return

    const extension = fileHelper.getExtension(path) || ''
    const types = this.getImageTypes()

    return types.indexOf(extension) !== -1
  },

  /**
     * loadImage
     *
     * Preload single image
     *
     * @param {string} path to preload
     * @param {object} [tenant] object (optional)
     */

  loadImage (path, tenant) {
    return new Promise(resolve => {
      const image = this.getFile(path, tenant)

      if (!this.isImage(image)) {
        resolve({ image, status: 'no-image' })
      } else {
        const img = new Image()
        img.onload = () => resolve({ image, status: 'ok' })
        img.onerror = () => resolve({ image, status: 'error' })
        img.src = image
      }
    })
  },

  /**
     * preload
     *
     * Preload images
     *
     * @param {array} images array of images
     * @param {object} [tenant] object (optional)
     */

  preload (images, tenant) {
    return Promise.all(images.map(file => this.loadImage(file, tenant)))
  }
}
