/**
 * Constants
 *
 * @desc Sentry constants
 */

export default {
  SENTRY: {
    RELEASE: `${process.env.DOMAIN}-frontend@${process.env.APP_VERSION}`,
    DSN: 'https://e9d50e4b0670435ca71cf7fd58e61694@o281606.ingest.sentry.io/5975536',
    ENVIRONMENTS: {
      development: [
        'localhost',
        'beterwonen.eigenhaard.nl.local'
      ],
      test: [
        'beterwonen.eigenhaard.nl.test.uselab.com'
      ],
      acceptance: [
        'beterwonen.accept.eigenhaard.nl'
      ],
      production: [
        'beterwonen.eigenhaard.nl'
      ]
    }
  }
}
