import Api from 'app/http/api'

const resource = '/landingpages'

export default {

  /**
     * getPageWithSlug()
     *
     * Retrieve landingpage based on a slug...
     *
     * @param {string} slug to pass
     * @returns {Promise<any>}
     *
     */

  getPageWithSlug (slug) {
    return Api().get(`${resource}/${slug}`)
  }
}
