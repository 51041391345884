/**
 * Device helper
 *
 * Helper methods for devices
 *
 * @author     K. van den Broek <k.vandenbroek@uselab.com>
 * @copyright  Uselab Amsterdam
 *
 */

export default {

  /**
     * isMobile
     *
     * Check device has TouchEvent
     */

  isMobile () {
    try {
      document.createEvent('TouchEvent')
      return true
    } catch (e) {
      return false
    }
  },

  /**
     * isIE
     *
     * Check userAgent is IE or Edge
     */

  isIE () {
    return (!!window.MSInputMethodContext && !!document.documentMode) || (navigator.userAgent.indexOf('Edge') !== -1)
  }
}
