<template>
  <div
    class="question question-list"
    :data-num-items="itemsCount"
  >
    <div class="question-content">
      <div class="prefix">
        {{ data.prefix }}
      </div>
      <div class="list-selector">
        <div
          class="list-selector--inner"
        >
          <ul>
            <li
              v-for="(option, index) in data.answer_options"
              :key="index"
              :class="{'size-animation': option.name.length < 12, 'selected': index == selectedIndex}"
              :data-value="option.value"
              @click="onClick(index)"
            >
              {{ option.name }}
            </li>
          </ul>
        </div>
      </div>
      <div class="suffix">
        {{ data.suffix }}
      </div>
    </div>
    <div class="tail" />
  </div>
</template>

<script>
import objectHelper from 'app/helpers/object'

import tool from 'app/mixin/tool'
import config from 'app/mixin/config'
import PerfectScrollbar from 'perfect-scrollbar'

export default {
  mixins: [tool, config],
  props: {
    data: {
      type: Object,
      default () {
        return {}
      }
    },
    value: {
      type: String,
      default () {
        return ''
      }
    },
    error: {
      type: Boolean
    }
  },
  emits: ['update:value', 'update:error'],
  data () {
    return {
      selectedIndex: null,
      scrollConfig: this.getConfig('scrollbar')
    }
  },
  computed: {
    itemsCount () {
      return this.data.answer_options.length
    }
  },
  mounted () {
    this.isDirty()
    const ps = new PerfectScrollbar('.list-selector--inner', {
      suppressScrollY: false,
      suppressScrollX: false,
      wheelPropagation: false
    })
    ps.update()
  },
  methods: {
    onClick (index) {
      this.selectedIndex = index
      this.isValid()
      this.$emit('update:value', this.data.answer_options[index].value)
    },
    isDirty () {
      const index = objectHelper.findIndex(this.data.answer_options, 'value', this.value)

      if (index === -1) {
        this.$emit('update:error', true)
      } else {
        this.selectedIndex = index
      }
    },
    isValid () {
      this.$emit('update:error', false)
    }
  }
}
</script>
