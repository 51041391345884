/**
 * dataLayer.js
 *
 * Generic mixin / abstraction layer to handle Gtm / Ga events...
 *
 * @author     Ken van den Broek    <k.vandenbroek@uselab.com>
 * @copyright  2019 Uselab.com
 *
 */

export default {
  methods: {
    gtmTrackEvent (data) {
      let error = false
      let handleCallback = false
      let timer = 200;

      ['category', 'action'].forEach(function (value) {
        if (typeof data[value] === 'undefined') error = true
      })

      const callback = function () {
        handleCallback = true

        if (typeof data.eventCallback === 'function') {
          data.eventCallback()
        }
      }

      if (!error) {
        timer += 800 // increase timer...

        this.$gtm.trackEvent({
          event: (data.event || 'interaction'),
          category: data.category,
          action: data.action,
          label: (data.label || null),
          eventCallback: callback,
          noninteraction: (data.nonInteraction || false)
        })
      }

      // timout to make sure callback method is called,
      //  even when GTM is down, unpublished or not responding..
      setTimeout(function () {
        if (handleCallback) return
        callback()
      }, timer)
    }
  }
}
