export default {
  getLandingpage (state) {
    return state.landingpage
  },
  getQuestions (state) {
    return state.questions
  },
  getInfo (state) {
    return state.info
  },
  getTransition (state) {
    return state.transition
  },
  getResult (state) {
    return state.result
  },
  getRegulation: (state) => (id) => {
    const result = state.result || {}
    const regulationId = Number(id)
    const dummy = {
      title: '',
      sub_title: '',
      details: '',
      requirement: {
        title: '',
        body: ''
      },
      followup: {
        form: {
          id: null,
          name: ''
        }
      }
    }

    if (!Object.keys(result).length || (state.result.regulations && !state.result.regulations.length)) return dummy

    return result.regulations.find(regulation => regulation.id === regulationId)
  },
  getSolution (state) {
    return state.solution
  },
  getFinished (state) {
    return state.finished
  },
  getUniqueId (state) {
    return state.uniqueId
  },
  getError (state) {
    return state.error
  },
  getFormFeedback (state) {
    return state.formFeedback
  }
}
