import objectHelper from 'app/helpers/object'

export default {

  /**
   * getAll
   *
   * Get tenant data
   */

  getAll () {
    return this.get()
  },

  /**
   * get
   *
   * Get tenant data
   *
   * @param {string} [prop] optional string to find/return
   */

  get (prop) {
    const tenant = window.tenant || null
    const property = prop || ''

    if (!tenant) return

    return objectHelper.get(property, tenant)
  },

  /**
   * get
   *
   * Get tenant data
   *
   * @param {string} [prop] optional string to find/return
   */

  getTenantPublicPath () {
    return (window.tenant.domain || '').replace(/\.+/g, '-')
  },

  /**
   * get
   *
   * Get tenant data
   *
   * @param {string} [prop] optional string to find/return
   */

  getThrobber () {
    const path = this.getTenantPublicPath()
    return `/domain/${path}/lottie/spinner.json`
  }
}
