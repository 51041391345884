/**
 * RepositoryFactory.js
 *
 * Factory to define all API repositories
 *
 * @author     Ken van den Broek    <k.vandenbroek@uselab.com>
 * @copyright  2019 Uselab.com
 *
 */

import QuestionRepository from 'app/repositories/QuestionRepository'
import ResultRepository from 'app/repositories/ResultRepository'
import LandingpageRepository from 'app/repositories/LandingpageRepository'
import FormRepository from 'app/repositories/FormRepository'

import DomainFormRepository from './FormRepository'

const repositories = {
  result: ResultRepository,
  question: QuestionRepository,
  landingpage: LandingpageRepository,
  form: { ...FormRepository, ...DomainFormRepository }
}

export const RepositoryFactory = {
  get: name => repositories[name]
}
