import { defineAsyncComponent } from 'vue'

const asyncComponents = {
  'b-tooltip': defineAsyncComponent(() => import(/* webpackChunkName: "components" */ './shared/b-tooltip.vue')),
  'default-top': defineAsyncComponent(() => import(/* webpackChunkName: "components" */ './partials/default-top.vue')),
  'landing-bottom': defineAsyncComponent(() => import(/* webpackChunkName: "components" */ './partials/landing-bottom.vue')),
  'question-bottom': defineAsyncComponent(() => import(/* webpackChunkName: "components" */ './partials/question-bottom.vue')),
  'question-top': defineAsyncComponent(() => import(/* webpackChunkName: "components" */ './partials/question-top.vue')),
  'result-bottom': defineAsyncComponent(() => import(/* webpackChunkName: "components" */ './partials/result-bottom.vue'))
}

export default {
  ...asyncComponents
}
