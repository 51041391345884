/**
 *
 * Constants
 *  Applications constants...
 *
 * @author     K. van den Broek <k.vandenbroek@uselab.com>
 * @copyright  Uselab Amsterdam
 *
 */

import DEFAULTS from 'app/constants/defaults'

import domainDefaults from './defaults'
import domainSentry from './sentry'

export default {
  ...DEFAULTS,
  ...domainDefaults,
  ...domainSentry
}
