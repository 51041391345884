<template>
  <div>
    <page />
    <error />
  </div>
</template>

<script>
import Page from 'app/components/layout/page'
import Error from 'app/components/shared/error'

export default {
  name: 'App',
  components: {
    page: Page,
    error: Error
  },
  data () {
    return {
      error: null
    }
  },
  mounted () {
    this.$store.watch((state, getters) => getters.getError, this.setError, {
      deep: true
    })
  },
  methods: {
    setError (data) {
      if (!data) {
        this.error = null
      } else {
        this.error = data
      }
    }
  }
}
</script>
