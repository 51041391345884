/**
 * viewport.js
 *
 * Generic directive to handle viewport height...
 *
 * @author     Ken van den Broek    <k.vandenbroek@uselab.com>
 * @copyright  2019 Uselab.com
 *
 */

/**
 * updateViewPort
 *
 * Calculates the height to be attached to the directive dom element...
 *
 * @params  event       default event
 * @params  args        arguments to use for calculation
 *
 */

const updateViewPort = (event, args) => {
  if (!args.element) return

  const element = args.element
  const subtract = args.subtract
  let viewportH = window.innerHeight

  if (subtract) {
    viewportH -= subtract.clientHeight
  }

  element.style.minHeight = viewportH + 'px'
}

/**
 * getConfig
 *
 * Get correct configuration arguments to pass to updateViewPort method...
 *
 * @params  el          dom element
 * @params  binding     directive bindings
 *
 */

const getConfig = (el, binding) => {
  let subtract = null

  if (typeof binding.value === 'object') {
    subtract = (binding.value.subtract || null)
  }

  return {
    element: el,
    subtract: (subtract ? document.getElementById(binding.value.subtract) : null)
  }
}

export default {

  /**
     * bind
     *
     * Default bind hook which adds dom class and event listeners...
     *
     * @params  el  el to attach
     *
     */

  beforeMount (el, binding) {
    el.classList.add('viewport-height')

    const args = getConfig(el, binding)

    window.addEventListener('resize', (event) => updateViewPort(event, args), false)
    window.addEventListener('scroll', (event) => updateViewPort(event, args), false)

    updateViewPort(null, args)
  },

  /**
     * inserted
     *
     * Default inserted hook which re-calls the updateViewPort method...
     *
     */

  mounted (el, binding) {
    const args = getConfig(el, binding)
    updateViewPort(null, args)
  },

  /**
     * updated
     *
     * Default updated hook which re-calls the updateViewPort method...
     *
     */

  updated (el, binding) {
    const args = getConfig(el, binding)
    updateViewPort(null, args)
  },

  /**
     * unbind
     *
     * Unregister the event listener before destroying this Vue instance...
     *
     */

  unmounted () {
    window.removeEventListener('resize', updateViewPort)
    window.removeEventListener('scroll', updateViewPort)
  }
}
