export default {
  isDebug () {
    return process.env.VUE_APP_DEBUG === 'true'
  },
  getCurrentEnvironment (envArray) {
    let env = null

    for (const i in envArray) {
      if (Object.prototype.hasOwnProperty.call(envArray, i) && envArray[i].indexOf(window.location.hostname) !== -1) {
        env = i
        break
      }
    }

    return env
  }
}
