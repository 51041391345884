/**
 * Api
 *
 * Api Axios configurations...
 *
 * @author     K. van den Broek <k.vandenbroek@uselab.com>
 * @copyright  Uselab Amsterdam
 *
 */

import axios from 'axios'

const getApiPath = () => {
  const domain = process.env.DOMAIN.replace(/-/g, '.')
  return process.env.NODE_ENV === 'development' ? `http://${domain}.local/api` : '/api'
}

export default () => {
  const api = axios.create({
    baseURL: getApiPath(),
    withCredentials: false,
    headers: {
      Accept: 'application/json',
      'Content-Type': 'multipart/form-data'
    }
  })

  api.interceptors.response.use((response) => {
    return response
  }, function (error) {
    return Promise.reject(error.response)
  })

  return api
}
