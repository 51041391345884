import { createGtm } from '@gtm-support/vue-gtm'

export default (vueRouter) => {
  return createGtm({
    id: process.env.VUE_APP_GTM,
    debug: (process.env.VUE_APP_MODE === 'development'),
    enabled: true,
    loadScript: true,
    vueRouter
  })
}
