import Api from 'app/http/api'

const resource = '/questions'

export default {

  /**
     * getIntro()
     *
     * Handle Api endpoint to get the (question) intro...
     *
     * @returns {Promise<any>}
     *
     */

  getIntro () {
    return Api().get(`${resource}/intro`)
  },

  /**
     * getNextQuestion()
     *
     * Handle Api endpoint to fetch result data based on hash parameter...
     *
     * @param {object} data object to get the correct next question (based on user answers)
     * @returns {Promise<any>}
     *
     */

  getNextQuestion (data) {
    return Api().post(`${resource}/next`, data)
  }
}
