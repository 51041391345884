import localforageService from 'app/services/localforage'

export default {
  methods: {
    getStorageItem (key) {
      return localforageService.getItem(key)
    },
    setStorageItem (key, value) {
      return localforageService.setItem(key, value)
    }
  }
}
