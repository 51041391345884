/**
 *
 * Config
 *
 * Service which returns general and static configurations...
 *
 * @author     K. van den Broek <k.vandenbroek@uselab.com>
 * @copyright  Uselab Amsterdam
 *
 */

import constants from 'domain/constants/index'
import objectHelper from 'app/helpers/object'

export default {
  get (prop, data) {
    const configValue = objectHelper.get(prop, constants)

    return (data ? objectHelper.mergeDeep(configValue, data) : configValue)
  }
}
