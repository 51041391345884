<template>
  <div>
    <div
      id="content"
      v-viewport
    >
      <page-header text="" />
      <default-top />
      <template v-if="!submitted">
        <div class="section section-tertiary section--padding-b">
          <div class="container">
            <div class="row">
              <div class="column col-lg-6 text-center offset-lg-3">
                <div class="pt-4">
                  <h1>{{ regulation.title }}</h1>
                  <p>{{ regulation.details }}</p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="section section-quaternary">
          <div class="container">
            <div class="row">
              <div class="column column col-lg-6 text-center offset-lg-3 pb-5">
                <div class="block block-solution block-primary">
                  <h2>
                    {{ regulation.requirement.title }}
                  </h2>
                  <div
                    v-dompurify-html="regulation.requirement.body"
                    class="mb-3"
                  />
                </div>
              </div>
              <div
                v-if="regulation.followup.form"
                class="column col-lg-6 text-left offset-lg-3"
              >
                <div class="text-center">
                  <h3 class="mb-1">
                    {{ $t('request-regulation-form.title') }}
                  </h3>
                  <p
                    v-dompurify-html="$t('request-regulation-form.intro')"
                    class="mb-5"
                  />
                </div>
                <request-regulation-form
                  class="pb-3"
                  @submitted="onSubmit"
                />
              </div>
            </div>
          </div>
        </div>
      </template>
      <template v-else>
        <div class="section section-tertiary section--padding-b text-center">
          <h1>{{ $t('contact.form.response.success.title') }}</h1>
          <p>{{ $t('contact.form.response.success.body') }}</p>
        </div>
      </template>
    </div>
    <footer-contact />
  </div>
</template>

<script>
import store from 'domain/store/index'

import { mapState } from 'vuex'
import Cookies from 'js-cookie'

import PageHeader from 'app/components/shared/page-header'

import image from 'app/mixin/image'
import storage from 'app/mixin/storage'

import arrayHelper from 'app/helpers/array'
import resultHelper from 'app/helpers/result'
import imageHelper from 'app/helpers/image'
import tenantHelper from 'app/helpers/tenant'

export default {
  name: 'Regulation',
  components: {
    PageHeader
  },
  mixins: [image, storage],
  beforeRouteEnter (to, from, next) {
    store.dispatch('resetFormEigenHaardBeterWonen')

    const formId = to.params.formId || null

    if (!formId) {
      next()
    } else {
      store.dispatch('getFormInfo', formId).then(() => {
        next()
      })
    }
  },
  data () {
    return {
      landing: Cookies.get('landingpage'),
      headerText: tenantHelper.get('payoff'),
      isLogoVisible: 'md',
      submitted: false
    }
  },
  computed: {
    ...mapState(['loading', 'result']),
    regulation () {
      return this.$store.getters.getRegulation(this.$route.params.id)
    }
  },
  mounted () {
    this.scrollToTop()
    this.getResult()
  },
  unmounted () {
    this.submitted = false
  },
  methods: {
    scrollToTop () {
      window.scrollTo(0, 0)
    },
    getResult () {
      if (resultHelper.isValidResult(this.result)) {
        this.preloading = true
        this.preload(this.result)
      } else {
        this.$store.dispatch('setLoading', {
          type: 'throbber',
          active: true
        })

        this.getStorageItem('result').then(value => {
          this.$store.dispatch('restoreResult', value).then(this.handleResult)
        }).catch(error => {
          window.console && console.error(error) // eslint-disable-line no-console
        })
      }
    },
    preload (data) {
      const images = []

      if (arrayHelper.isArray(data.regulations) && data.regulations.length) {
        data.regulations.forEach((value) => {
          images.push(value.path)
        })
      }

      imageHelper.preload(images).then(() => this.handleResult())
    },
    handleResult () {
      if (resultHelper.isValidResult(this.result)) {
        setTimeout(
          function () {
            this.preloading = false
            this.$store.dispatch('setLoading', {
              active: false
            })
          }.bind(this),
          100
        )
      } else {
        const routeTo = this.landing ? `/landing/${this.landing}` : '/404'
        this.$router.push(routeTo)
      }
    },
    onSubmit () {
      this.submitted = true
    }
  }
}
</script>
