<template>
  <div class="container container-header">
    <span
      v-if="text !== ''"
      class="h5 alt d-block ts-max-md--m ts-text--l"
    >{{ text }}</span>
    <div class="button">
      <button
        class="btn-icon btn-previous"
        @click.prevent="onBackClick"
      />
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import objectHelper from 'app/helpers/object'
import Cookies from 'js-cookie'

export default {
  name: 'PageHeader',
  props: {
    text: {
      type: String,
      default () {
        return ''
      }
    }
  },
  data () {
    return {
      landing: Cookies.get('landingpage')
    }
  },
  computed: {
    ...mapState([
      'questions'
    ])
  },
  methods: {
    getReturnPath (currentRoute) {
      const data = {}

      data.name = null
      data.params = {}

      switch (currentRoute.name) {
      // when current route is contact page,
      //  route to result detail page...
        case 'contact':
          data.name = 'result-detail'
          data.params.id = currentRoute.params.id
          break

        // when current route is result detail page,
        //  return to result page...
        case 'result-detail':
          data.name = 'result'
          data.params.id = currentRoute.params.id
          break
      }

      // when params are not needed, delete from object...
      if (objectHelper.isEmpty(data.params)) {
        delete data.params
      }

      return data
    },
    onBackClick () {
      // when current route is 'result' make sure to
      //  redirect to previous question, or to landing...
      if (this.$route.name === 'result') {
        if (this.questions.length) {
          this.$store.dispatch('resetFinished')
          this.$router.push({ name: 'question', params: { action: 'previous-question' } })
        } else {
          this.$router.push(`/landing/${this.landing}`)
        }
      } else {
        const returnTo = this.getReturnPath(this.$route)

        if (returnTo.name) {
          this.$router.push(returnTo)
        } else {
          this.$router.back()
        }
      }
    }
  }
}
</script>
