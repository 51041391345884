import objectHelper from 'app/helpers/object'

export default {

  /**
     * configurations
     *
     */

  config: {
    route: {
      home: 'Home',
      landing: 'Welkom',
      question: 'Vragenlijst',
      result: 'Regelingen',
      'result-detail': 'Regeling informatie',
      contact: 'Neem contact op',
      'contact-form-submitted': 'Aanvraag verstuurd',
      'page-not-found': 'Pagina niet gevonden'
    }
  },

  /**
     * getPageTitle
     *
     * Get page title from configurations
     *
     * @param {string} route string
     */

  getPageTitle (route) {
    return `${this.get('route.' + route)}`
  },

  /**
     * get
     *
     * Get item from object with optional deep merge
     *
     * @param {prop} prop string
     * @param {object} [data] optional object to merge
     */

  get (prop, data) {
    let config = objectHelper.get(prop, this.config)

    if (typeof data !== 'undefined') {
      config = objectHelper.mergeDeep(config, data)
    }

    return config
  }
}
