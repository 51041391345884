/**
 * Routes
 *
 * All application route configurations
 *
 * @author     K. van den Broek <k.vandenbroek@uselab.com>
 * @copyright  Uselab Amsterdam
 *
 */

import Content from 'app/views/Content'
import Landing from 'app/views/Landing'
import Question from 'app/views/Question'
import routeHelper from 'app/helpers/route'
import tenantHelper from 'app/helpers/tenant'

import Result from 'domain/views/Result'
import ResultDetail from 'domain/views/ResultDetail'

export default [
  {
    path: '/',
    redirect () {
      return '/landing/' + tenantHelper.get('default_landing_page')
    }
  },
  {
    path: '/landing/:slug?',
    name: 'landing',
    component: Landing,
    meta: {
      gtm: routeHelper.getPageTitle('landing')
    }
  },
  {
    path: '/question/:action?',
    name: 'question',
    component: Question,
    props: {
      default: true
    },
    meta: {
      gtm: routeHelper.getPageTitle('question')
    }
  },
  {
    path: '/result',
    name: 'result',
    component: Result,
    meta: {
      gtm: routeHelper.getPageTitle('result')
    }
  },
  {
    path: '/result/detail/:id',
    component: ResultDetail,
    children: [
      {
        path: '',
        name: 'result-detail',
        component: ResultDetail,
        meta: {
          gtm: routeHelper.getPageTitle('result-detail')
        }
      }
    ]
  },
  {
    path: '/404',
    name: '404',
    component: Content,
    props: {
      title: 'page-not-found.title',
      content: 'page-not-found.content'
    },
    meta: {
      gtm: routeHelper.getPageTitle('page-not-found')
    }
  },
  {
    path: '/:pathMatch(.*)*',
    redirect: '/'
  }
]
