import { createRouter, createWebHistory } from 'vue-router'

// Domain route configurations
import routes from 'domain/routes/index'

// Create Vue Router instance
const vueRouter = createRouter({
  history: createWebHistory(),
  routes
})

// Export Vue Router
export default vueRouter
