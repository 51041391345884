export default {

  /**
     *
     * isValidResult()
     *
     * Validate result data which is decrypted by the api based on a hash...
     *
     * We added this method to make sure Views are rendered correctly at any time,
     *  because when result data changes it can break the View...
     *
     * @params {object} data object to validate
     *
     */

  isValidResult (data) {
    if (typeof data === 'undefined') return false

    let key
    const items = {
      regulations: 'Array',
      suggestions: 'Array'
    }

    for (key in items) {
      const value = data[key]

      // first; check whether key is within the data set,
      //  otherwise return false...
      if (typeof value === 'undefined') {
        return false
      } else {
        // second; we make sure the data is of the correct type (Object, Array etc)...
        if (
          (items[key] === 'Array' && !Array.isArray(value)) ||
                    (items[key] === 'Object' && typeof value !== 'object')
        ) {
          return false
        }
      }
    }

    return true
  },

  /**
     *
     * has()
     *
     * Return true when regulations...
     *
     * @params {object} object to validate
     * @params {string} type / key to validate
     * @return {boolean} true/false
     *
     */

  has (result, type) {
    return (typeof result[type] !== 'undefined' && result[type].length !== 0)
  }
}
