<template>
  <div>
    <div id="content">
      <default-top />
      <div class="container">
        <h1>{{ $t(title) }}</h1>
        <div v-dompurify-html="renderHtml" />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    title: {
      type: String,
      default () {
        return ''
      }
    },
    content: {
      type: String,
      default () {
        return ''
      }
    }
  },
  computed: {
    renderHtml () {
      return this.$t(this.content)
    }
  }
}
</script>
