export default {

  /**
     * hasQuestionType
     *
     * Object has valid question_type
     *
     * @param {object} question object to check
     */

  hasQuestionType (question) {
    if (!question) return

    const questionType = question.question_type

    return (typeof questionType === 'object' && typeof questionType.type !== 'undefined')
  },

  /**
     * getQuestionType
     *
     * Return the question type from question object
     *
     * @param {object} question object
     */

  getQuestionType (question) {
    if (!this.hasQuestionType(question)) return

    return question.question_type.type
  },

  /**
     * getUserAnswers
     *
     * Return user answers
     *
     * @param {array} data array
     * @param {array} removeItems items to remove from array (optional)
     */

  getUserAnswers (data, removeItems) {
    const answers = [...data]
    const remove = removeItems || []

    // remove the first and last item item,
    //  because it's no 'real' answer but intro/exit...
    if (remove.indexOf('first') !== -1) {
      answers.shift()
    }

    if (remove.indexOf('last') !== -1) {
      answers.pop()
    }

    answers.forEach(function (item) {
      delete item.error
    })

    return answers
  }
}
