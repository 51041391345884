import { createStore } from 'vuex'

import state from 'app/store/state'
import actions from 'app/store/actions'
import getters from 'app/store/getters'
import mutations from 'app/store/mutations'

import domainState from './state'
import domainActions from './actions'
import domainGetters from './getters'
import domainMutations from './mutations'

export default createStore({
  state: { ...state, ...domainState },
  actions: { ...actions, ...domainActions },
  getters: { ...getters, ...domainGetters },
  mutations: { ...mutations, ...domainMutations }
})
