/**
 * Theme
 *
 * Create tenant theme...
 *
 * @author     K. van den Broek <k.vandenbroek@uselab.com>
 * @copyright  Uselab Amsterdam
 *
 */

import Api from 'app/http/api'

class Theme {
  /**
   * Constructor
   *
   * @params {array}  preload images to preload...
   *
   */

  constructor () {
    this.endpoint = '/tenants/current'
    this.tenant = {}
  }

  /**
   * Load
   *
   * Retrieves all domain information from API...
   *
   * @return  {Promise} promise
   *
   */

  load () {
    return new Promise(function (resolve, reject) {
      if (this.tenant.name) {
        resolve(this.tenant)
      }

      Api().get(this.endpoint).then(function (response) {
        if (!response.data.name || !response.data.domain) {
          reject('Higher-level error. Tenant name and domain should be defined in response data.')
        } else {
          this.store(response.data)
          this.append('favicon')
          this.append('stylesheet')
          resolve(this.tenant)
        }
      }.bind(this)).catch(function (error) {
        reject('Higher-level error. ' + error)
      })
    }.bind(this))
  }

  /**
   * Store
   *
   * Store Tenant in class instance
   *
   * @param {object} tenant
   *
   */

  store (tenant) {
    Object.assign(this.tenant, (tenant || null))
  }

  /**
   * Append
   *
   * Append Tenant asset(s) to application document
   *
   * In development mode (served by vue-cli) page will be rendered
   * by 'index.html', instead of the 'frontend.blade.php'...
   *
   * @param {string} type to set
   *
   */

  append (type) {
    const title = document.getElementsByTagName('title')
    const link = document.createElement('link')

    switch (type) {
      case 'favicon':
        link.href = `/domain/${this.tenant.domain}/favicon.png`
        link.rel = 'icon'
        break
      case 'stylesheet':
        link.href = `/domain/${this.tenant.domain}/css/index.css`
        link.type = 'text/css'
        link.rel = 'stylesheet'
        break
      default:
        break
    }

    document.getElementsByTagName('head')[0].insertBefore(link, title[0])
  }
}

export default Theme
