import { defineAsyncComponent } from 'vue'

const asyncComponents = {
  FooterContact: defineAsyncComponent(() => import(/* webpackChunkName: "components" */ './FooterContact.vue')),
  RequestRegulationForm: defineAsyncComponent(() => import(/* webpackChunkName: "components" */ './RequestRegulationForm.vue'))
}

export default {
  ...asyncComponents
}
