/**
 * Http helper
 *
 * Helper methods for HTTP
 *
 * @author     K. van den Broek <k.vandenbroek@uselab.com>
 * @copyright  Uselab Amsterdam
 *
 */

export default {

  /**
     * isHttpError
     *
     * Status object of API calls
     *
     * @param {object} obj
     */

  isHttpError (obj) {
    const httpStatus = [404, 500]
    return typeof obj === 'object' && httpStatus.indexOf(obj.status) !== -1
  }
}
