<template>
  <div class="error">
    <transition
      name="slide"
      @after-leave="removeError"
    >
      <div
        v-show="visible"
        class="alert alert-danger"
      >
        {{ getMessage }}
      </div>
    </transition>
  </div>
</template>

<script>
import { mapState } from 'vuex'

export default {
  name: 'Error',
  data () {
    return {
      visible: false,
      dismissMs: 5000
    }
  },
  computed: {
    ...mapState([
      'error'
    ]),
    getMessage () {
      if (!this.error) return

      return this.$t('errors.' + this.error.type)
    }
  },
  watch: {
    error: {
      handler () {
        this.handleError()
      }
    }
  },
  methods: {
    handleError () {
      if (!this.error) return

      this.visible = true

      setTimeout(function () {
        this.visible = false
      }.bind(this), this.dismissMs)
    },
    removeError () {
      this.$store.dispatch('resetError')
    }
  }
}
</script>
