import Api from 'app/http/api'

const resource = '/results'

export default {

  /**
     * getResult()
     *
     * Handle Api endpoint to fetch result based on user answer data...
     *
     * @returns {Promise<any>}
     *
     */

  getResult (data) {
    return Api().post(`${resource}`, data)
  },

  /**
     * restoreResult()
     *
     * Handle Api endpoint to fetch result data based on hash parameter...
     *
     * @param data
     * @returns {Promise<any>}
     *
     */

  restoreResult (data) {
    return Api().post(`${resource}/restore`, data)
  },

  /**
     * restoreResult()
     *
     * Handle Api endpoint to fetch result data based on hash parameter...
     *
     * @param id
     * @returns {Promise<any>}
     *
     */

  getRegulation (id) {
    return Api().get(`${resource}/regulations/${id}`)
  },

  /**
     * getSolution()
     *
     * Handle Api endpoint to fetch result data based on hash parameter...
     *
     * @param id
     * @returns {Promise<any>}
     *
     */
  getSolution (id) {
    return Api().get(`${resource}/solutions/${id}`)
  }
}
