import { RepositoryFactory } from 'domain/repositories/RepositoryFactory'

const FormRepository = RepositoryFactory.get('form')

export default {
  resetFormEigenHaardBeterWonen (context) {
    context.commit('SET_FORM_FEEDBACK', {
      data: {
        errors: []
      }
    })
  },
  submitFormEigenHaardBeterWonen (context, form) {
    context.commit('SET_LOADING', {
      type: 'throbber',
      active: true
    })

    return new Promise(function (resolve) {
      FormRepository.submitFormEigenHaardBeterWonen(form.data).then(r => r.data)
        .then(data => {
          resolve(data)
        }).catch(function (response) {
          switch (response.status) {
            case 413: // catch max size exceeded  error
              context.commit('SET_FORM_FEEDBACK', {
                data: {
                  errors: {
                    max_size_exeeded: true
                  }
                }
              })
              break
            case 400: // general validation messages
              context.commit('SET_FORM_FEEDBACK', response)
              break
            default:
              context.commit('SET_FORM_FEEDBACK', {
                data: {
                  errors: {
                    unknown_error: true
                  }
                }
              })
              break
          }
        }).finally(function () {
          context.commit('SET_LOADING', {
            active: false
          })
        })
    })
  }
}
