import Api from 'app/http/api'

const resource = '/forms'

export default {

  /**
     * submit()
     *
     * Post form data, which will generate the e-mail
     *
     * @param {id} form id
     * @param {data} data
     * @returns {Promise<any>}
     *
     */

  submit (form, data) {
    return Api().post(`${resource}/${form}`, data)
  },

  /**
     * getFormInfo()
     *
     * Retrieves form information (eg. contact options),
     *  this can be extended in the future when we need dynamic form generation...
     *
     * @param {id} id of form
     * @returns {Promise<any>}
     *
     */

  getFormInfo (id) {
    return Api().get(`${resource}/${id}`)
  }
}
