<template>
  <div>
    <div
      id="content"
      v-viewport
    >
      <default-top />
      <div class="container container--landingpage">
        <div class="row">
          <div class="col-md-6 offset-md-3">
            <div class="block block-default mb-40px text-center">
              <h1>{{ landingpage.title }}</h1>
              <div
                class="image img-landing"
                data-height="200"
              >
                <lottie-animation
                  v-if="type === 'json'"
                  id="animation"
                  :path="image"
                  :autoplay="true"
                  :loop="true"
                />
                <img
                  v-else-if="type !== 'json'"
                  :key="image"
                  :src="image"
                  :alt="landingpage.title"
                >
              </div>
              <p class="ts-text--m">
                {{ landingpage.text }}
              </p>
              <router-link
                :to="{name: 'question'}"
                class="btn btn-primary btn-lg mt-4 mb-4"
              >
                {{ $t('landing.link.start') }}
              </router-link>

              <transition
                name="fade"
                mode="out-in"
              >
                <div v-if="hasResult">
                  <a
                    class="ts-text--m link-previous-result mb-4"
                    href=""
                    @click.prevent="onResultClick()"
                  >{{ $t('landing.link.previous-result') }}</a>
                </div>
              </transition>
            </div>
          </div>
        </div>
      </div>
    </div>
    <landing-bottom />
  </div>
</template>

<script>

import Cookies from 'js-cookie'

import store from 'domain/store/index'
import { mapState } from 'vuex'
import LottieAnimation from 'app/components/shared/lottie-animation'

// mixins
import image from 'app/mixin/image'
import datalayer from 'app/mixin/datalayer'
import storage from 'app/mixin/storage'

// helpers
import httpHelper from 'app/helpers/http'
import imageHelper from 'app/helpers/image'

const getLandingpage = (to, from, next) => {
  store.dispatch('getLandingpage', to.params.slug).then(function (response) {
    next()
  }).catch(response => {
    if (httpHelper.isHttpError(response)) {
      next({ name: '404' })
    }
  })
}

const beforeRouteEnter = getLandingpage
const beforeRouteUpdate = getLandingpage

export default {
  name: 'Landing',
  components: {
    LottieAnimation
  },
  mixins: [image, datalayer, storage],
  beforeRouteEnter,
  beforeRouteUpdate,
  data () {
    return {
      type: null,
      image: null,
      hasResult: false
    }
  },
  computed: {
    ...mapState([
      'result',
      'landingpage'
    ])
  },
  watch: {
    'landingpage.path': {
      immediate: true,
      handler () {
        imageHelper.loadImage(this.landingpage.path).then(() => {
          this.setData(this.landingpage)
        })
      }
    }
  },
  mounted () {
    this.getStorageItem('result').then(value => {
      if (!value) return
      this.$store.dispatch('restoreResult', value).then(this.handleResult)
    }).catch(error => {
      window.console && console.error(error) // eslint-disable-line no-console
    })
  },
  methods: {
    onResultClick () {
      this.gtmTrackEvent({
        category: 'Button',
        action: 'Click',
        label: 'Navigate to previous results',
        eventCallback: function () {
          this.$router.push({ name: 'result' })
        }.bind(this)
      })
    },
    setData (data) {
      console.log(data)
      Cookies.set('landingpage', data.slug)

      const file = data.path || null

      if (!file) return

      this.type = file.split('.')[1]
      this.image = this.getFile(file)
    },
    handleResult () {
      setTimeout(function () {
        this.hasResult = true
      }.bind(this), 0)
    }
  }
}
</script>
