/**
 * main.js
 *
 * Core application file and bootstrap
 *
 * @author     Ken van den Broek    <k.vandenbroek@uselab.com>
 * @copyright  2021 Uselab.com
 *
 */

/* -----------------------------------------------------------------
 * 3rd party
 * -------------------------------------------------------------- */
import VueDOMPurifyHTML from 'vue-dompurify-html'

/* -----------------------------------------------------------------
 * core
 * -------------------------------------------------------------- */
import createModule from 'app/core/module'
import i18n from './core/localization'
import Theme from './core/theme'
import initGtm from './core/gtm'

import coreDirectives from './directives/directives'
import coreComponents from './components/components'

/* -----------------------------------------------------------------
* app
* -------------------------------------------------------------- */
import App from './App.vue'
import router from './router'

/* -----------------------------------------------------------------
 * domain
 * -------------------------------------------------------------- */
import store from 'domain/store/index'
import domainComponents from 'domain/components/components'

/* -----------------------------------------------------------------
 * prepare
 * -------------------------------------------------------------- */
const components = { ...coreComponents, ...domainComponents }
const directives = { ...coreDirectives }

/* -----------------------------------------------------------------
 * init
 * -------------------------------------------------------------- */
const theme = new Theme()

const renderApp = (tenant) => {
  window.tenant = tenant

  const gtm = initGtm(router)

  createModule({
    App,
    components,
    directives,
    router,
    dependencies: [i18n, store, gtm, VueDOMPurifyHTML]
  })
}

const renderError = (message) => {
  window.console && console.error(message) // eslint-disable-line no-console
}

theme.load().then(renderApp).catch(renderError)
