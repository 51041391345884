<template>
  <div>
    <div
      v-if="!hasRegulations"
      id="content"
      class="content-mh"
    >
      <page-header text="" />
      <default-top />
      <div
        v-show="loading.active && loading.type === 'throbber'"
        class="throbber mt-3"
      >
        <lottie-animation
          id="spinner"
          :path="lottieSpinner"
          :autoplay="true"
          :loop="true"
        />
      </div>
      <div v-show="!loading.active || preloading">
        <div class="section section-tertiary">
          <div class="container">
            <div class="row">
              <div class="column col-lg-6 text-center offset-lg-3">
                <div>
                  <div class="pb-3">
                    <img
                      class="illustration"
                      :src="getFile(image)"
                      :alt="title"
                    >
                  </div>
                  <h1>{{ title }}</h1>
                  <div
                    v-dompurify-html="body"
                    class="mb-3"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div
      v-else
      id="content"
      v-viewport="{ subtract: 'bottom' }"
      class="viewport-height"
    >
      <page-header text="" />
      <default-top />
      <div
        v-show="loading.active && loading.type === 'throbber'"
        class="throbber mt-3"
      >
        <lottie-animation
          id="spinner"
          :path="lottieSpinner"
          :autoplay="true"
          :loop="true"
        />
      </div>
      <div v-show="!loading.active || preloading">
        <div class="section section-tertiary">
          <div class="container">
            <div class="row">
              <div class="column col-lg-6 text-center offset-lg-3">
                <div>
                  <div class="pb-3">
                    <img
                      class="illustration"
                      :src="getFile(image)"
                      :alt="title"
                    >
                  </div>
                  <h1>{{ title }}</h1>
                  <div
                    v-dompurify-html="body"
                    class="mb-3"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="section section-quaternary pb-5">
          <div class="container">
            <div class="row">
              <div class="column column col-lg-6 text-center offset-lg-3">
                <div
                  v-for="(item, index) in result.regulations"
                  :key="'solutions-' + index"
                  class="block block-solution block-primary"
                >
                  <h2>
                    {{ item.title }} <br>
                    {{ item.sub_title }}
                  </h2>
                  <div
                    v-dompurify-html="item.details"
                    class="mb-3"
                  />
                  <router-link
                    v-if="item.followup.title.length"
                    class="btn btn-secondary mb-3"
                    :to="{
                      name: 'result-detail',
                      params: { id: item.id }
                    }"
                  >
                    {{ item.followup.title }}
                  </router-link>
                  <div class="ts-text--m text-gray-06">
                    {{ item.followup.body }}
                  </div>
                </div>
                <div
                  v-for="(item, index) in result.suggestions"
                  :key="index"
                  class="block block-solution block-secondary"
                >
                  <h3>{{ item.title }}</h3>
                  <div>{{ item.text }}</div>
                  <a
                    :href="item.link"
                    class="arrow-right"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    {{ item.link_text }}</a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <footer-contact />
  </div>
</template>

<script>
import { mapState } from 'vuex'
import Cookies from 'js-cookie'

import PageHeader from 'app/components/shared/page-header'
import LottieAnimation from 'app/components/shared/lottie-animation'

import image from 'app/mixin/image'
import storage from 'app/mixin/storage'

import arrayHelper from 'app/helpers/array'
import resultHelper from 'app/helpers/result'
import imageHelper from 'app/helpers/image'
import tenantHelper from 'app/helpers/tenant'

export default {
  name: 'ResultHuurverhoging',
  components: {
    PageHeader,
    LottieAnimation
  },
  mixins: [image, storage],
  data () {
    return {
      preloading: false,
      landing: Cookies.get('landingpage'),
      backRoute: 'question',
      bottomHidden: false,
      headerText: tenantHelper.get('payoff'),
      isLogoVisible: 'sm'
    }
  },
  computed: {
    ...mapState(['result', 'loading']),
    lottieSpinner () {
      return tenantHelper.getThrobber()
    },
    hasRegulations () {
      return this.has('regulations')
    },
    hasSuggestions () {
      return this.has('suggestions')
    },
    image () {
      return this.hasRegulations
        ? 'illustration/illustration-qualifies.svg'
        : 'illustration/illustration-does-not-qualify.svg'
    },
    title () {
      return this.hasRegulations
        ? this.$t('result.qualifies.title')
        : this.$t('result.does_not_qualify.title')
    },
    body () {
      return this.hasRegulations
        ? this.$t('result.qualifies.body')
        : this.$t('result.does_not_qualify.body')
    }
  },
  mounted () {
    if (resultHelper.isValidResult(this.result)) {
      this.preloading = true
      this.preload(this.result)
    } else {
      this.$store.dispatch('setLoading', {
        type: 'throbber',
        active: true
      })

      this.getStorageItem('result').then(value => {
        this.$store.dispatch('restoreResult', value).then(this.handleResult)
      }).catch(error => {
        window.console && console.error(error) // eslint-disable-line no-console
      })
    }
  },
  methods: {
    has (type) {
      return resultHelper.has(this.result, type)
    },
    suggestionsColumnOffsetClass (index) {
      const count = this.result.suggestions.length

      if (index > 0 || count >= 3) return

      let cssClass = 'offset-lg-'

      switch (count) {
        case 1:
          cssClass += 4
          break
        case 2:
          cssClass += 2
          break
        default:
          break
      }

      return cssClass
    },
    preload (data) {
      const images = []

      if (arrayHelper.isArray(data.regulations) && data.regulations.length) {
        data.regulations.forEach((value) => {
          images.push(value.path)
        })
      }

      imageHelper.preload(images).then(() => this.handleResult())
    },
    handleResult () {
      if (resultHelper.isValidResult(this.result)) {
        setTimeout(
          function () {
            this.preloading = false
            this.$store.dispatch('setLoading', {
              active: false
            })
          }.bind(this),
          100
        )
      } else {
        const routeTo = this.landing ? `/landing/${this.landing}` : '/404'
        this.$router.push(routeTo)
      }
    }
  }
}
</script>

<style lang="scss" scoped>
 #content.content-mh {
   min-height: calc(100vh - 86px);
 }
</style>
