import ConfigService from 'app/services/config'

export default {
  beforeMount (el, binding) {
    const handler = () => {
      setTimeout(function () {
        let element = el

        if (typeof binding.value.el !== 'undefined') {
          element = document.getElementById(binding.value.el)
        }

        const bodyRect = document.body.getBoundingClientRect()
        const elementRect = element.getBoundingClientRect()
        const offset = (elementRect.top - bodyRect.top) + (binding.value.offset || 0)
        const startingY = window.pageYOffset
        const elementY = offset

        let start

        const duration = (binding.value.speed || 500)

        // if element is close to page's bottom then window will scroll only to some position above the element...
        const targetY = document.body.scrollHeight - elementY < window.innerHeight ? document.body.scrollHeight - window.innerHeight : elementY
        const diff = targetY - startingY

        const easeInOutCubic = function (t) {
          return t < 0.5 ? 4 * t * t * t : (t - 1) * (2 * t - 2) * (2 * t - 2) + 1
        }

        if (!diff) return

        // bootstrap our animation,
        //  it will get called right before next frame shall be rendered...
        window.requestAnimationFrame(function step (timestamp) {
          if (!start) start = timestamp

          const time = timestamp - start // elapsed milliseconds since start of scrolling...
          let percent = Math.min(time / duration, 1) // get percent of completion in range [0, 1]

          // apply the easing, it can cause bad-looking
          //  slow frames in browser performance tool, so be careful...
          percent = easeInOutCubic(percent)

          window.scrollTo(0, startingY + diff * percent)

          // proceed with animation as long as we wanted it to.
          if (time < duration) {
            window.requestAnimationFrame(step)
          }
        })
      }, ConfigService.get('timers.timeMsToScroll'))
    }

    const scrollOn = binding.value.scrollOn || null

    if (scrollOn && scrollOn === 'init') {
      handler()
    } else {
      el.addEventListener('click', handler)
      el.$destroy = () => el.removeEventListener('click', handler)
    }
  },
  unmounted: function (el) {
    if (typeof el.$destroy !== 'function') return

    el.$destroy()
  }
}
