import { RepositoryFactory } from 'domain/repositories/RepositoryFactory'
import ConfigService from 'app/services/config'
import questionHelper from 'app/helpers/question'

const LandingpageRepository = RepositoryFactory.get('landingpage')
const QuestionRepository = RepositoryFactory.get('question')
const ResultRepository = RepositoryFactory.get('result')
const FormRepository = RepositoryFactory.get('form')

export default {
  setProgress (context, data) {
    context.commit('SET_PROGRESS', data)
  },
  setToolResult (context, data) {
    context.commit('SET_TOOL_RESULT', data)
  },
  setLoading (context, data) {
    context.commit('SET_LOADING', {
      type: data.type || 'default',
      active: data.active || false
    })
  },
  setTransitionType (context, type) {
    context.commit('SET_TRANSITION', type)
  },
  restoreResult (context, hash) {
    return new Promise(function (resolve) {
      const response = ResultRepository.restoreResult({
        hash
      })

      response.then(r => r.data).then(data => {
        context.commit('STORE_RESULT', data)
        resolve(data)
      }).catch(function (data) {
        context.commit('SET_ERROR', data)
      })
    })
  },
  getRegulation (context, id) {
    return new Promise(function (resolve, reject) {
      const response = ResultRepository.getRegulation(id)

      response.then(r => r.data).then(data => {
        context.commit('SET_REGULATION', data)
        resolve(data)
      }).catch(function (data) {
        context.commit('SET_ERROR', data)
        reject(response)
      })
    })
  },
  getSolution (context, id) {
    return new Promise(function (resolve, reject) {
      const response = ResultRepository.getSolution(id)

      response.then(r => r.data).then(data => {
        context.commit('STORE_SOLUTION', data)
        resolve(data)
      }).catch(function (data) {
        context.commit('SET_ERROR', data)
        reject(response)
      })
    })
  },
  getResult (context, userAnswers) {
    return new Promise(function (resolve, reject) {
      const answers = questionHelper.getUserAnswers(userAnswers, ['first', 'last'])
      const response = ResultRepository.getResult({
        answers
      })

      response.then(r => r.data).then(data => {
        context.commit('STORE_RESULT', data)
        resolve(data)
      }).catch(function (data) {
        context.commit('SET_ERROR', data)
        reject(response)
      })
    })
  },
  getLandingpage (context, slug) {
    return new Promise(function (resolve, reject) {
      const response = LandingpageRepository.getPageWithSlug(slug)

      response.then(r => r.data).then(data => {
        context.commit('SET_LANDINGPAGE', data)
        context.commit('SET_INFO', data.info)
        resolve(data)
      }).catch(function (data) {
        reject(data)
      })
    })
  },
  getIntro (context) {
    context.commit('SET_LOADING', {
      type: 'default',
      active: true
    })

    const response = QuestionRepository.getIntro()

    response.then(r => r.data).then(data => {
      const intro = ConfigService.get('templates.intro', {
        question: {
          text: data.text,
          info: data.info
        }
      })

      context.commit('STORE_QUESTION', intro)
      context.commit('SET_ACTIVE_QUESTION')
      context.commit('SET_ACTIVE_ANSWER')
      context.commit('SET_INFO')
    }).catch(function (data) {
      context.commit('SET_ERROR', data)
    }).then(function () {
      context.commit('SET_LOADING', {
        active: false
      })
    })
  },
  getNextQuestion (context, userAnswers) {
    const answers = questionHelper.getUserAnswers(userAnswers, ['first'])
    const response = QuestionRepository.getNextQuestion({
      answers
    })

    context.commit('SET_LOADING', {
      type: 'default',
      active: true
    })

    return response.then(r => r.data).then(data => {
      let finished = false
      let questionData = data

      if (data.status === 'finished') {
        finished = true
        questionData = ConfigService.get('templates.exit', {
          progress: data.progress
        })
      }

      context.commit('SET_FINISHED', finished)
      context.commit('STORE_QUESTION', questionData)
      context.commit('SET_ACTIVE_QUESTION')
      context.commit('SET_ACTIVE_ANSWER')
      context.commit('SET_PROGRESS')
      context.commit('SET_INFO')
    }).catch(function (data) {
      context.commit('SET_ERROR', data)
    }).then(function () {
      setTimeout(function () {
        context.commit('SET_LOADING', {
          active: false
        })
      }, 1000)
    })
  },
  getPreviousQuestion (context, from) {
    if (from !== 'result') {
      context.commit('REMOVE_QUESTION')
    }

    context.commit('SET_FINISHED', false)
    context.commit('SET_ACTIVE_QUESTION')
    context.commit('SET_ACTIVE_ANSWER')
    context.commit('SET_PROGRESS')
    context.commit('SET_INFO')
  },
  getFormInfo (context, formId) {
    return new Promise(function (resolve) {
      FormRepository.getFormInfo(formId).then(r => r.data).then(data => {
        context.commit('SET_CONTACT_OPTIONS', data.contact_options)
        resolve(data)
      })
    })
  },
  submitForm (context, form) {
    context.commit('SET_LOADING', {
      type: 'throbber',
      active: true
    })

    return new Promise(function (resolve) {
      FormRepository.submit(form.name, form.data).then(r => r.data).then(data => {
        resolve(data)
      }).catch(function (response) {
        context.commit('SET_ERROR', response)
      }).then(function () {
        context.commit('SET_LOADING', {
          active: false
        })
      })
    })
  },
  resetActiveQuestion (context) {
    context.commit('RESET_ACTIVE_QUESTION')
  },
  resetQuestionnaire (context) {
    return new Promise(function (resolve) {
      context.commit('RESET_TRANSITION')
      context.commit('RESET_PROGRESS')
      context.commit('RESET_ACTIVE_QUESTION')
      context.commit('RESET_QUESTIONS')
      context.commit('RESET_ANSWERS')
      context.commit('RESET_FINISHED')

      setTimeout(() => resolve({ reset: true }), 400)
    })
  },
  resetError (context) {
    context.commit('SET_ERROR')
  },
  resetFinished (context) {
    context.commit('REMOVE_QUESTION')
    context.commit('RESET_ACTIVE_QUESTION')
    context.commit('RESET_ACTIVE_ANSWER')
    context.commit('SET_FINISHED', false)
  }
}
