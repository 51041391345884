<template>
  <div>
    <div
      id="content"
      v-scroll="{el: 'page', scrollOn: scroll.scrollOn, speed: scroll.speed}"
      v-viewport
    >
      <div class="container position-relative">
        <question-top />
        <div class="container container-fixed-width">
          <div

            class="question-container"
          >
            <transition
              :name="transition"
              mode="out-in"
              @after-leave="afterLeave"
              @before-leave="animating = true"
              @after-enter="animating = false"
            >
              <component
                :is="computedQuestionType"
                v-if="showComponent"
                :key="computedUniqueId"
                v-model:value="computedQuestionAnswerValue"
                v-model:error="computedQuestionAnswerError"
                :data="question"
              />
            </transition>

            <div
              class="loading-animation"
              :class="{'loading-animation--active' : loadingQuestion}"
            >
              <div v-show="loadingQuestion">
                <lottie-animation
                  id="spinner"
                  :path="throbber"
                  :autoplay="true"
                  :loop="true"
                />
              </div>
            </div>
          </div>
          <div
            class="question-navigation"
            :class="{'question-navigation--hidden' : finished}"
          >
            <button
              class="btn-icon btn-previous"
              :disabled="prevDisabled"
              @click="onPreviousQuestionClick"
            />
            <button
              class="btn-icon btn-next"
              :disabled="nextDisabled"
              @click="onNextQuestionClick"
            />
          </div>
        </div>
      </div>
    </div>
    <question-bottom :finished="finished" />
  </div>
</template>

<script>
import store from 'domain/store/index'
import { mapState } from 'vuex'

import questionHelper from 'app/helpers/question'

import datalayer from 'app/mixin/datalayer'
import config from 'app/mixin/config'

import numeric from 'app/components/question-types/numeric'
import list from 'app/components/question-types/list'
import text from 'app/components/question-types/text'
import dateq from 'app/components/question-types/date'
import LottieAnimation from 'app/components/shared/lottie-animation'
import tenantHelper from 'app/helpers/tenant.js'
import Cookies from 'js-cookie'
import storage from 'app/mixin/storage'

export default {
  name: 'Question',
  components: {
    LottieAnimation,
    'question-text': text,
    'question-numeric': numeric,
    'question-list': list,
    'question-date': dateq
  },
  mixins: [datalayer, storage, config],
  beforeRouteEnter (to, from, next) {
    const action = to.params.action || ''

    if (action === 'previous-question') {
      next(vm => {
        const name = from.name || ''
        vm.replaceRoute()
        vm.handlePrevious(name)
      })
    } else {
      store.dispatch('resetQuestionnaire').then(function () {
        store.dispatch('getIntro').then(function () {
          next()
        })
      })
    }
  },
  props: {
    action: {
      type: String,
      default: ''
    }
  },
  data () {
    return {
      throbber: tenantHelper.getThrobber(),
      animating: false, // used to disable prev / next buttons while animating
      loadingQuestion: false, // added because this.loadings timing is off. I could not use it for showing/hiding questions
      showComponent: true,
      slug: '', // used for to landing page back button
      scroll: {
        scrollOn: 'init',
        speed: 400
      }
    }
  },
  computed: {
    ...mapState([
      'loading',
      'question',
      'questions',
      'answer',
      'answers',
      'finished',
      'transition'
    ]),
    hasPrevious () {
      return (this.questions.length > 1)
    },
    nextDisabled () {
      return (this.answer.error || this.loading.active || this.finished || this.loadingQuestion || this.animating)
    },
    prevDisabled () {
      return (this.loading.active || this.loadingQuestion || this.animating)
    },
    computedQuestionType () {
      return 'question-' + questionHelper.getQuestionType(this.question)
    },
    computedUniqueId () {
      return `${this.computedQuestionType}-${this.question.id}`
    },
    computedQuestionAnswerValue: {
      get () {
        return this.answer.value
      },
      set (value) {
        this.answer.value = value
      }
    },
    computedQuestionAnswerError: {
      get () {
        return this.answer.error
      },
      set (error) {
        this.answer.error = error
      }
    }
  },
  watch: {
    finished: {
      immediate: true,
      handler (value) {
        this.goToResult(value)
      }
    }
  },

  mounted () {
    store.dispatch('setProgress', { visible: true })
  },
  methods: {
    replaceRoute () {
      this.$router.push({ path: '/question', replace: true })
    },
    afterLeave () {
      if (this.loadingQuestion) {
        this.showComponent = false
      }
    },
    handleNext () {
      this.loadingQuestion = true

      this.$store.dispatch('setTransitionType', 'question-next')
      this.$store.dispatch('getNextQuestion', this.answers).then(() => {
        this.loadingQuestion = false
        this.showComponent = true
      })

      this.gtmTrackEvent({
        category: 'Button',
        action: 'Click',
        label: 'Next question'
      })
    },
    handlePrevious (value) {
      const from = value || ''

      this.$store.dispatch('setTransitionType', 'question-previous')
      this.$store.dispatch('getPreviousQuestion', from)

      this.gtmTrackEvent({
        category: 'Button',
        action: 'Click',
        label: 'Previous question'
      })
    },
    onNextQuestionClick () {
      this.handleNext()
      this.resetScrollPosition()
    },
    onPreviousQuestionClick () {
      if (this.hasPrevious) {
        this.handlePrevious()
        this.resetScrollPosition()
      } else {
        const slug = Cookies.get('landingpage') || ''
        if (slug !== '') {
          this.$router.push(`/landing/${slug}`)
        }
      }
    },

    resetScrollPosition () {
      window.scroll({
        top: 0,
        left: 0,
        behavior: 'smooth' // text compatibility!
      })
    },
    goToResult (value) {
      if (!value) return

      const answers = this.answers

      setTimeout(function () {
        this.$store.dispatch('getResult', answers).then(function (data) {
          this.setStorageItem('result', data.hash)
          this.$router.push('/result')
        }.bind(this))
      }.bind(this), this.getConfig('timers.timeMsToShowResult'))
    }
  }
}
</script>
