export default {
  loading: {
    type: 'default',
    active: false
  },
  transition: 'question-next',
  uniqueId: 0,
  contact: [],
  error: null,
  formFeedback: {
    data: {
      errors: []
    }
  },
  landingpage: {},
  result: {
  },
  progress: {
    value: 0,
    visible: false
  },
  question: {},
  questions: [],
  answer: {},
  answers: [],
  info: {
    id: 0,
    title: '',
    body: '',
    tool: {}
  },
  finished: false,
  regulation: {},
  solution: {}
}
