/**
 *
 * Localization
 *
 * Service which returns the i18nConfig object...
 *
 * @author     K. van den Broek <k.vandenbroek@uselab.com>
 * @copyright  Uselab Amsterdam
 *
 */

import { createI18n } from 'vue-i18n'

const locale = localStorage.locale || 'nl'

const i18nConfig = {
  warnHtmlInMessage: 'off',
  locale,
  messages: {}
}

const data = {
  default: {},
  domain: {}
}

/**
 *
 * Define default language configurations
 *
 */

try {
  data.default = require(`../lang/${locale}.json`)
} catch (ex) {
  data.default = {}
}

/**
 *
 * Define domain language configurations
 *  These will overrule the 'default' language configurations...
 *
 */

if (typeof process.env.DOMAIN !== 'undefined') {
  try {
    data.domain = require(`../../domain/${process.env.DOMAIN}/lang/${locale}.json`)
  } catch (ex) {
    data.domain = {}
  }
}

/**
 *
 * Bind + export localization
 *
 */

i18nConfig.messages[locale] = { ...data.default, ...data.domain }

export default createI18n(i18nConfig)
